<template>
  <div
    class="index-banner"
    :style="{'background-image':'url(assets/icon/banner-bg.png)','background-size': '46% 35rem','background-repeat': 'no-repeat', 'background-position': 'right 0px'}"
  >
    <div class="container">
      <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-8 mb-5">
          <div class="banner-form section-title">
            <div class="p-4">
              <ul class="nav">
                <li class="nav-item">
                  <a
                    class="nav-link py-1 nav-link-style-1"
                    :class="tabs.company?'active':''"
                  >Company Detail</a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link py-1 nav-link-style-2"
                    :class="tabs.product?'active':''"
                  >Product Detail</a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link py-1 nav-link-style-2"
                    :class="tabs.payment?'active':''"
                  >Payment Detail</a>
                </li>
              </ul>
              <form @submit.prevent="submit" class="tab-content">
                <div class="tab-pane fade" :class="tabs.company?'show active':''">
                  <div class="row mt-4">
                    <div class="col-6">
                      <div class="row mb-2">
                        <div class="col-md-5 mr-0 pr-0">
                          <label for="plan" class="page-text mt-1">Company Name</label>
                        </div>
                        <div class="col-md-7">
                          <input type="text" placeholder="Comapany Name" class="form-control">
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-md-5 mr-0 pr-0">
                          <label for="plan" class="page-text mt-1">Company Address</label>
                        </div>
                        <div class="col-md-7">
                          <input type="text" placeholder="Comapany Address" class="form-control">
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-md-5 mr-0 pr-0">
                          <label for="plan" class="page-text mt-1">PAN/VAT No.</label>
                        </div>
                        <div class="col-md-7">
                          <input type="text" placeholder="Email" class="form-control">
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row mb-2">
                        <div class="col-md-5 mr-0 pr-0">
                          <label for="plan" class="page-text mt-1">Contact No.</label>
                        </div>
                        <div class="col-md-7">
                          <input type="text" placeholder="Phone No" class="form-control">
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-md-5 mr-0 pr-0">
                          <label for="plan" class="page-text mt-1">Email</label>
                        </div>
                        <div class="col-md-7">
                          <input type="text" placeholder="Email" class="form-control">
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-md-5 mr-0 pr-0">
                          <label for="plan" class="page-text mt-1">Workspace</label>
                        </div>
                        <div class="col-md-7">
                          <input type="text" placeholder="Comapany Name" class="form-control">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="text-right pt-3">
                    <button class="btn btn-sm btn-primary" @click="tabs.product=true">Next</button>
                  </div>
                </div>
                <div class="tab-pane fade" :class="tabs.product?'show active':''">
                  <div class="row mt-3">
                    <div class="col-md-6">
                      <div class="card bg-theam-secondary p-2">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">
                            <label for="product" class="page-text mt-1">Product</label>
                          </div>
                          <div class="col-md-7">
                            <select
                              class="form-control border-radius-0"
                              id="product"
                              required
                            >
                              <option value>Select Product</option>
                              <option>Service Oriented</option>
                              <option value="">Product Oriented</option>
                              <option value="">Manufacturing</option>
                              <option value="">Resturant Management System</option>
                            </select>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">
                            <label for="product" class="mt-1">Plan</label>
                          </div>
                          <div class="col-md-7">
                            <select
                              class="form-control border-radius-0"
                              id="product"
                              required
                            >
                              <option value>Select Plan</option>
                              <option>Basic</option>
                              <option value="">Standard</option>
                              <option value="">Premium</option>
                            </select>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">
                            <label for="product" class="mt-1">Subscription</label>
                          </div>
                          <div class="col-md-7">
                            <select
                              class="form-control border-radius-0"
                              id="product"
                              required
                            >
                              <option value>Select Type</option>
                              <option>Quarterly</option>
                              <option value="">Half Anually</option>
                              <option value="">Anually</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="card bg-theam-secondary p-2 mt-2">
                        <h6 class="mb-0">Do You want to verify from IRD ?</h6>
                        <div>
                          <input
                              type="radio"
                              name="ird-yes"
                              id="ird-yes"
                              value="1"
                              class="mr-2"
                              checked
                              v-model="formData.ird"
                            />
                            <label for="ird-yes" class="mr-3">YES</label>
                            <input
                              type="radio"
                              class="mr-2"
                              name="ird-no"
                              id="ird-no"
                              value="0"
                              v-model="formData.ird"
                            />
                            <label for="ird-no">NO</label>
                        </div>
                        <div class="group-note my-2">
                          <span>Note: </span> Your Sales Data will be only send to IRD when You allow to send choosing yes option from above. Other wise your data will be only accessable by your users. <a href="#">More Detail</a>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="card bg-theam-secondary p-2 ht-100p">
                          <table class="table">
                            <thead class="bg-primary">
                              <tr>
                                <th class="tx-white">Description</th>
                                <th class="tx-white">Qty.</th>
                                <th class="tx-white">Rate</th>
                                <th class="tx-white text-right">Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Basic Plan</td>
                                <td>3M</td>
                                <td>2000</td>
                                <td class="text-right">NPR 6000</td>
                              </tr>
                            </tbody>
                          </table>
                          <hr>
                          <div class="row">
                            <div class="col-md-3"></div>
                            <div class="col-md-9">
                              <ul class="list-unstyled lh-6 mb-0 print-sm-mg">
                                <li class="d-flex justify-content-between font-s">
                                  <span>Sub-Total</span>
                                  <span>NPR 6000</span>
                                </li>
                                <li class="d-flex justify-content-between font-s">
                                  <span>Discount</span>
                                  <span>NPR 1000</span>
                                </li>
                                <li class="d-flex justify-content-between font-s">
                                  <span>Vat Amount (13%)</span>
                                  <span>NPR 650</span>
                                </li>
                                <li class="d-flex justify-content-between font-s">
                                  <span>Bill Amount</span>
                                  <span>NPR 6000</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                  <div class="text-right pt-3">
                    <button class="btn btn-sm btn-primary" @click="tabs.payment=true">Checkout</button>
                  </div>
                </div>
                <div class="tab-pane fade" :class="tabs.payment?'show active':''">
                  <div class="font-xl my-3">
                    Bill Amount: NPR 5000
                  </div>
                  <h4>Select Payment Method</h4>
                  <div class="row wallet">
                    <div class="col-md-2">
                      <button class="payment-method-select"><img
                        src="assets/icon/wallet/esewa.jpg"
                        @click="formData.paymentmethod_id=1"
                        class="img-fluid payment-method-select"
                      /></button>
                    </div>
                    <div class="col-md-2">
                      <button class="payment-method-select">
                        <img
                          src="assets/icon/wallet/nic-asia-bank.png"
                          @click="formData.paymentmethod_id=2"
                          class="img-fluid payment-method-select"
                        />
                      </button>
                    </div>
                    <div class="col-md-2">
                      <button class="payment-method-select">
                        <img
                          src="assets/icon/wallet/khalti-digital-wallet.png"
                          @click="formData.paymentmethod_id=3"
                          class="img-fluid payment-method-select"
                        />
                      </button>
                    </div>
                    <div class="col-md-2">
                      <button class="payment-method-select">
                        <img
                          src="assets/icon/wallet/ime-pay.jpg"
                          class="img-fluid payment-method-select"
                          @click="formData.paymentmethod_id=4"
                        />
                      </button>
                    </div>
                  </div>
                  <div class="text-right pt-3">
                    <button class="btn btn-sm btn-primary">Place Order</button>
                  </div>
                  <!-- <div class="row">
                    <div class="col-md-12 text-right">
                      <button type="submit" class="btn btn-primary border-radius-0">PLACE ORDER</button>
                      <p
                        :class="eventMessage.indexOf('success')>=0?'success_level':'error_level'"
                      >{{eventMessage}}</p>
                    </div>
                  </div> -->
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <img src="assets/icon/mascot.png" class="img-fluid banner-image-fluid" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import {
  required,
  email,
  numeric,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  computed: {
    ...mapGetters([
      "apiRoot",
      "token",
      "processing",
      "eventMessage",
      "dataLists",
      "dataLists1"
    ]),
  },
  mounted() {
    this.$store.commit("getData1", "api/plans");
    this.getSubscriptions();
  },
  data() {
    return {
      tabs: {
        company: true,
        product: false,
        payment: false,
      },
      paymentDetails: {
        extra_user: 0,
        extra_user_cost: 0,
        extra_branch: 0,
        extra_branch_cost: 0,
        total: 0,
        total_cost: 0,
        subscription_discount: 0,
        subscription_discount_type: 1,
        promo_discount: 0,
        promo_discount_type: 1,
        discount_amount: 0,
        vatable_amount: 0,
        vat: 0,
        payable_amount: 0,
      },
      plan: [],
      subscriptions: [],
      formData: {
        company: "",
        address: "",
        email: "",
        contact: "",
        pan: "",
        ird: 1,
        plan_id: "",
        workspace: "",
        subscription: "",
        branches: "",
        users: "",
        promo_code: "",
        paymentmethod_id: "",
      },
    };
  },
  validations: {
    formData: {
      company: { required, minLength: minLength(2), maxLength: maxLength(255) },
      address: { required, minLength: minLength(2), maxLength: maxLength(255) },
      email: { required, email, minLength: minLength(6) },
      contact: { required, numeric, minLength: minLength(7) },
      pan: {
        required,
        numeric,
        minLength: minLength(9),
        maxLength: maxLength(9),
      },
      plan_id: { required, numeric },
      workspace: { required, minLength: minLength(4) },
      subscription: { required, numeric },
      branches: { required, numeric },
      users: { required, numeric },
    },
  },
  methods: {
    checkWorkspace() {
      if (this.formData.workspace) {
        this.$store.commit(
          "getData",
          `api/workspace/${this.formData.workspace}`
        );
      }
    },
    checkPromocode() {
      if (this.formData.promo_code) {
        axios
          .get(`${this.apiRoot}api/promo/${this.formData.promo_code}`, {
            headers: { Authorization: `Bearer ${this.token}` },
          })
          .then((res) => {
            if (res.data.success_message) {
              this.paymentDetails.promo_discount = res.data.data.discount;
              this.paymentDetails.promo_discount_type = res.data.data.type;
            } else {
              this.$store.commit("setEventMessage", res.data.error_message);
              this.paymentDetails.promo_discount = 0;
              this.paymentDetails.promo_discount_type = 1;
            }
            this.calculatePayment();
          })
          .catch((error) => {
            this.$store.commit("manageErrors", error);
          });
      }
    },
    planDetail() {
      if (this.formData.plan_id) {
        axios
          .get(`${this.apiRoot}api/plan/${this.formData.plan_id}`, {
            headers: { Authorization: `Bearer ${this.token}` },
          })
          .then((res) => {
            this.plan = res.data;
          })
          .catch((error) => {
            this.$store.commit("manageErrors", error);
          });
      }
    },
    submit() {
      this.$store.commit("setApiUrl", "api/order/store");
      this.$store.commit("addData", {
        ...this.paymentDetails,
        ...this.formData,
      });
    },
    calculatePayment() {
      let disc1 = 0;
      let disc2 = 0;
      this.paymentDetails.total =
        this.plan.price +
        this.paymentDetails.extra_branch_cost +
        this.paymentDetails.extra_user_cost;
      if (this.formData.subscription > 0) {
        this.paymentDetails.total_cost =
          this.paymentDetails.total * this.formData.subscription;
      } else {
        this.paymentDetails.total_cost = this.paymentDetails.total;
      }
      if (this.paymentDetails.subscription_discount_type == 1) {
        disc1 = this.paymentDetails.subscription_discount;
      } else {
        disc1 =
          (this.paymentDetails.total_cost *
            this.paymentDetails.subscription_discount) /
          100;
      }
      if (this.paymentDetails.promo_discount_type == 1) {
        disc2 = this.paymentDetails.promo_discount;
      } else {
        disc2 =
          ((this.paymentDetails.total_cost - disc1) *
            this.paymentDetails.promo_discount) /
          100;
      }
      this.paymentDetails.discount_amount = disc1 + disc2;
      this.paymentDetails.vatable_amount =
        this.paymentDetails.total_cost - this.paymentDetails.discount_amount;
      this.paymentDetails.vat = (this.paymentDetails.vatable_amount * 13) / 100;
      this.paymentDetails.payable_amount =
        this.paymentDetails.total_cost - this.paymentDetails.vat;
      console.log("payment details", this.paymentDetails);
    },
    getSubscriptions() {
      axios
        .get(`${this.apiRoot}api/subscriptions`, {
          headers: { Authorization: `Bearer ${this.token}` },
        })
        .then((res) => {
          this.subscriptions = res.data;
        })
        .catch((error) => {
          this.$store.commit("manageErrors", error);
        });
    },
  },
  watch: {
    "tabs.product"(value) {
      if (value == true) {
        this.tabs.company = false;
        this.tabs.payment = false;
      }
    },
    "tabs.company"(value) {
      if (value == true) {
        // if (
        //   this.formData.company &&
        //   this.formData.workspace &&
        //   this.formData.address &&
        //   this.formData.email &&
        //   this.formData.contact &&
        //   this.formData.pan
        // ) {
        //   this.tabs.company = false;
        // } else {
        //   this.tabs.product = false;
        //   this.tabs.payment = true;
        //   this.$store.commit("setEventMessage", "Please fill required fields.");
        // }
        this.tabs.payment = false;
        this.tabs.product = false;
      }
    },
    "tabs.payment"(value) {
      if (value == true) {
        // if (
        //   this.formData.plan_id &&
        //   this.formData.subscription &&
        //   this.formData.branches &&
        //   this.formData.users
        // ) {
        //   this.tabs.product = false;
        // } else {
        //   this.tabs.payment = false;
        //   this.tabs.product = true;
        //   this.$store.commit("setEventMessage", "Please fill required fields.");
        // }
        this.tabs.company = false;
        this.tabs.product = false;
      }
    },
    "formData.workspace"(value) {
      if (value) {
        this.formData.workspace = value
          .toLowerCase()
          .trim()
          .split(".")
          .join("")
          .replace(/[\s\/]/g, "");
      } else {
        this.error = "";
      }
    },
    eventMessage(value) {
      if (value && value.indexOf("success") >= 0) {
        this.formData.company = "";
        this.formData.address = "";
        this.formData.email = "";
        this.formData.contact = "";
        this.formData.pan = "";
        this.formData.plan_id = "";
        this.formData.workspace = "";
      }
    },
    "formData.subscription"(value) {
      if (value) {
        let disc = this.subscriptions.find(function (data) {
          return data.value == value;
        });
        this.paymentDetails.subscription_discount = disc.discount;
        this.paymentDetails.subscription_discount_type = disc.type;
      }
      this.calculatePayment();
    },
    plan() {
      this.calculatePayment();
    },
    "formData.branches"() {
      if (this.formData.branches > this.plan.branches) {
        this.paymentDetails.extra_branch =
          this.formData.branches - this.plan.branches;
        this.paymentDetails.extra_branch_cost =
          this.paymentDetails.extra_branch * this.plan.extra_branch_cost.price;
      } else {
        this.paymentDetails.extra_branch = 0;
        this.paymentDetails.extra_branch_cost = 0;
      }
      this.calculatePayment();
    },
    "formData.users"() {
      if (this.formData.users > this.plan.users) {
        this.paymentDetails.extra_user = this.formData.users - this.plan.users;
        this.paymentDetails.extra_user_cost =
          this.paymentDetails.extra_user * this.plan.extra_user_cost.price;
      } else {
        this.paymentDetails.extra_user = 0;
        this.paymentDetails.extra_user_cost = 0;
      }
      this.calculatePayment();
    },
  },
};
</script>

<style scoped>
a .next {
  padding: 8px 15px 8px 15px;
  border: 1px solid white;
  margin: 5px 0px 5px 0px;
  margin-right: -5rem;
  font-size: 13px;
}
.nav-item .active,
.nav-item .active:hover {
  color: white;
  background: #1b51be !important;
  cursor: grab;
}
</style>